import React from "react";
import "./Cover.css";

const Cover = ({ title, description, dots }) => (
  <div className="Cover">
    <div className="Cover__copy">
      <div className="Cover__header">{title}</div>
      {description && (
        <div
          className="Cover__paragraph"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}
    </div>
  </div>
);

Cover.displayName = "Cover";

export default Cover;
