import Home from '../pages/Home';
import Courses from '../pages/Courses';
import Contact from '../pages/Contact';
import Presentations from '../pages/Presentations';
import Portfolio from '../pages/Portfolio';
import Partners from '../pages/Partners';
import courses from './courses';
import presentations from './presentations';
import portfolio from './portfolio';
import partners from './partners';
import home from './home';
import contact from './contact';

const pages = [
  {
    id: 'home',
    name: '',
    Component: Home,
    data: home,
  },
  {
    id: 'courses',
    name: 'Szkolenia',
    Component: Courses,
    data: courses,
  },
  {
    id: 'presentations',
    name: 'Prezentacje',
    Component: Presentations,
    data: presentations,
  },
  {
    id: 'portfolio',
    name: 'Portfolio',
    Component: Portfolio,
    data: portfolio,
  },
  {
    id: 'partners',
    name: 'Zaufali nam',
    Component: Partners,
    data: partners,
  },
  {
    id: 'contact',
    name: 'Kontakt',
    Component: Contact,
    data: contact,
  },
];

export default pages;
