import React from "react";
import emitter from "tiny-emitter/instance";
import Item from "./Item";
import "./BottomMenu.css";

class BottomMenu extends React.PureComponent {
  state = { scrollingTo: null };

  handleScrollStart = scrollingTo => {
    const { items } = this.props;
    if (!items.map(({ id }) => id).includes(scrollingTo)) {
      return;
    }

    this.setState({ scrollingTo });
  };

  handleScrollEnd = () => {
    this.setState({ scrollingTo: null });
  };

  addListeners = () => {
    emitter.on("scroll-start", this.handleScrollStart);
    emitter.on("scroll-end", this.handleScrollEnd);
  };

  removeListeners = () => {
    emitter.off("scroll-start", this.handleScrollStart);
    emitter.off("scroll-end", this.handleScrollEnd);
  };

  componentDidMount() {
    this.addListeners();
  }

  componentWillUnmount() {
    this.removeListeners();
  }

  render() {
    const { items, scrollHovers } = this.props;
    const { scrollingTo } = this.state;

    const hoveredItem = items
      .map(({ id }) => id)
      .find(id => scrollHovers[id]);

    const active = scrollingTo || hoveredItem;

    return (
      <div className="BottomMenu">
        {items.map(({ id, label }) => (
          <Item
            key={id}
            name={label}
            selected={active === id}
            onClick={() => emitter.emit("scroll-to", id)}
          />
        ))}
      </div>
    );
  }
}

BottomMenu.displayName = "BottomMenu";

export default BottomMenu;
