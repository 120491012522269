const portfolio = {
  cover: {
    title: 'Portfolio',
  },
  projects: [
    {
      id: 'prologis',
      title: {
        PL: `Konferencja “Forum kobiet w logistyce”`,
        EN: `"Women of logistics" conference`,
      },
      description: `
        Naszym zadaniem było stworzenie projektu wpisującego 
        się zarówno w identyfikację wizualną klienta, jak 
        również zawierającego elementy podkreślające 
        kobiecy charakter spotkania. W rezultacie powstała 
        elegancka biznesowa prezentacja zawierająca 
        przykłady, metafory i quizy nie tylko ze świata 
        logistyki.
        `,
      client: 'Prologis',
    },
    {
      id: 'captain-morgan',
      title: `Rum w Polsce ma jednego kapitana`,
      client: 'Captain Morgan',
      description: `
        Naszym zadaniem było zaprojektowanie layoutu 
        prezentacji, który odzwierciedlałby charakter 
        produktu. W rezultacie powstała prezentacja pełna 
        pirackich i żeglarskich motywów.
        `,
    },
    {
      id: 'samsung-notes',
      title: `Materiały szkoleniowe`,
      client: 'Samsung',
      description: `
        Uczestnicy naszych szkoleń doceniają nasze 
        pomysły na oryginalne pomoce naukowe, które 
        ułatwiają wdrożenie wiedzy w praktykę biznesową. 
        Co więcej projektujemy też materiały szkoleniowe 
        dla naszych klientów na ich szkolenia wewnętrzne 
        i szkolenia prowadzone przez zewnętrznych 
        partnerów.
        `,
    },
    {
      id: 'samsung-logos',
      title: `Programy HR`,
      client: 'Samsung',
      description: `
        Ważnym elementem wielu naszych projektów są 
        ikony i ikonografiki ułatwiające publiczności 
        zrozumienie skomplikowanych treści. Dlatego 
        nasze prezentacje to nieoceniona pomoc w trakcie 
        ważnych i trudnych spotkań.
                `,
    },
    {
      id: 'ihouse',
      title: `Prezentacja badań`,
      client: 'Ihouse',
      description: `
      W studio Present Perfect nie tylko „uładniamy” 
      slajdy, ale także wspólnie z naszymi klientami 
      pracujemy nad atrakcyjnym story prezentacji, 
      szukamy przykładów, metafor i sposobów 
      ułatwiających zrozumienie i zapamiętanie treści. 
      Prezentacja ihouse to jeden z tych projektów, 
      w których zaangażowaliśmy słuchaczy dzięki 
      atrakcyjnym quizom i pytaniom do publiczności.
        `,
    },
    {
      id: 'abbott',
      title: `Prezentacje danych`,
      client: 'Abbott',
      description: `
      Większość naszych projektów zwiera dużą ilość
      danych liczbowych. Staramy się ułatwić ich 
      zrozumienie projektując przejrzyste wykresy, 
      schematy i infografiki. I nie boimy się Excela 😊 
        `,
    },
  ],
};

export default portfolio;
