const importLogos = () => {
  const context = require.context(
    '../assets/partners/',
    true,
    /\.(png|jpg|jpeg|gif|svg|PNG|JPG|JPEG|GIF|SVG)$/
  );
  const files = {};

  context.keys().forEach(filename => {
    const path = filename.split('/');
    const name = path[path.length - 1];
    files[name] = context(filename);
  });

  return files;
};

const logos = importLogos();

export default filename => logos[filename] || '';
