import React from "react";
import Cover from "../../components/Cover";
import Steps from "../../components/Steps";
import Prezi from "../../components/Prezi";
import "./Courses.css";

const Courses = ({ data }) => (
  <div className="Courses App__section">
    <Cover {...data.cover} />
    <Steps steps={data.steps} />
    <Prezi data={data.prezi} />
  </div>
);

Courses.displayName = "Courses";

export default Courses;
