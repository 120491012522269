import types from "./types";

export const setScrollHover = scrollHover => ({
  type: types.MENU_SET_SCROLL_HOVER,
  scrollHover
});

export const unsetScrollHover = scrollHover => ({
  type: types.MENU_UNSET_SCROLL_HOVER,
  scrollHover
});

export const setLanguage = language => ({
  type: types.MENU_SET_LANGUAGE,
  language
});
