import { connect } from "react-redux";
import {
  setScrollHover,
  unsetScrollHover
} from "../../store/menu/actions";

import Scroller from "./Scroller";

const mapStateToProps = (state, ownProps) => ({
  scrollHover: state.menu.scrollHovers[ownProps.id]
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  setScrollHover: () => dispatch(setScrollHover(ownProps.id)),
  unsetScrollHover: () => dispatch(unsetScrollHover(ownProps.id))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Scroller);
