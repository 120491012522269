import types from "./types";

const initialState = {
  language: "PL",
  scrollHovers: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.MENU_SET_SCROLL_HOVER:
      return {
        ...state,
        scrollHovers: {
          ...state.scrollHovers,
          [action.scrollHover]: true
        }
      };
    case types.MENU_UNSET_SCROLL_HOVER:
      return {
        ...state,
        scrollHovers: {
          ...state.scrollHovers,
          [action.scrollHover]: false
        }
      };
    case types.MENU_SET_LANGUAGE:
      return {
        ...state,
        language: action.language
      };
    default:
      return state;
  }
};
