const home = {
  head: {
    display: "Make your<br />Presence Perfect",
    lead: {
      PL:
        "szkolenia &nbsp;|&nbsp;&nbspprezentacje &nbsp;|&nbsp&nbspghostwriting",
      EN:
        "courses &nbsp;|&nbsp;&nbsppresentations &nbsp;|&nbsp;&nbspghostwriting"
    }
  }
};
export default home;
