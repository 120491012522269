import getPartnerLogo from '../utils/getPartnerLogo';

const partners = {
  cover: {
    title: 'Zaufali nam',
  },
  logos: [
    {
      name: 'Abbott',
      image: getPartnerLogo('abbott.png'),
    },
    {
      name: 'Amica',
      image: getPartnerLogo('amica.jpg'),
    },
    {
      name: 'Continental',
      image: getPartnerLogo('continental.png'),
    },
    {
      name: 'Contrust',
      image: getPartnerLogo('contrust.jpg'),
    },
    {
      name: 'Kampania Piwowarska',
      image: getPartnerLogo('kampania-piwowarska.jpg'),
    },
    {
      name: 'Mylan',
      image: getPartnerLogo('mylan.png'),
    },
    {
      name: 'OMD',
      image: getPartnerLogo('omd.png'),
    },
    {
      name: 'Prologis',
      image: getPartnerLogo('prologis.png'),
    },
    {
      name: 'Publicis Groupe',
      image: getPartnerLogo('publicis-groupe.jpg'),
    },
    {
      name: 'Samsung',
      image: getPartnerLogo('samsung.png'),
    },
    {
      name: 'Shell',
      image: getPartnerLogo('shell.png'),
    },
    {
      name: 'Velphoro',
      image: getPartnerLogo('velphoro.png'),
    },
    {
      name: 'Radio Zet',
      image: getPartnerLogo('zet.jpg'),
    },
  ],
};

export default partners;
