import React from 'react';
import Cover from '../../components/Cover';
import './Contact.css';
import primaryLogo from '../../assets/svg/present-perfect-logo.svg';

const Contact = ({ data }) => (
  <div className="Contact App__section">
    <Cover title={data.cover.title} />
    <div className="Contact__content">
      <img
        key="primary"
        src={primaryLogo}
        className="Contact__logo"
        alt="Present Perfect"
      />
      <div className="Contact__data">
        <div
          className="Contact__title"
          dangerouslySetInnerHTML={{ __html: data.title }}
        />
        <div
          className="Contact__description"
          dangerouslySetInnerHTML={{ __html: data.address }}
        />
        <div className="Contact__description">
          tel:&nbsp;
          <a
            href={`tel:+${data.telephone.prefix} ${data.telephone.number}`}
            className="Contact__link"
          >
            (+{data.telephone.prefix}) {data.telephone.number}
          </a>
          <br />
          e-mail:&nbsp;
          <a href={`mailto:${data.email}`} className="Contact__link">
            {data.email}
          </a>
        </div>
      </div>
    </div>
  </div>
);

Contact.displayName = 'Contact';

export default Contact;
