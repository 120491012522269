import React from "react";

const Item = ({ name, selected, onClick }) => (
  <div
    className={`TopMenu__item ${selected ? "TopMenu__item--selected" : ""}`}
    onClick={onClick}
  >
    {name}
  </div>
);

Item.displayName = "Item";

export default Item;
