import React from "react";

import "./Image.css";

class Image extends React.PureComponent {
  state = { loaded: false };
  ref = React.createRef();

  handleLoad = () => {
    this.setState({ loaded: true });
  };
  handleLoadStart = () => {
    this.setState({ loaded: false });
  };

  render() {
    const { className, ...imageAttributes } = this.props;
    const { loaded } = this.state;

    return (
      <img
        alt="slide"
        {...imageAttributes}
        ref={this.ref}
        onLoad={this.handleLoad}
        onLoadStart={this.handleLoadStart}
        className={`Image ${className} ${loaded ? "Image--loaded" : ""}`}
      />
    );
  }
}

Image.displayName = "Image";

export default Image;
