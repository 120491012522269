import React from 'react';
import emitter from 'tiny-emitter/instance';
import './Logo.css';
import primaryLogo from '../../../assets/svg/present-perfect-logo.svg';
import secondaryLogo from '../../../assets/svg/pp-logo.svg';

const Logo = ({ scrollHovers }) => {
  const primary =
    Object.keys(scrollHovers).length === 0 || scrollHovers.home === true;

  return (
    <div className="Logo" onClick={() => emitter.emit('scroll-to', 'home')}>
      <img
        key="primary"
        src={primaryLogo}
        className={`Logo__image Logo__primary-image ${
          primary ? 'Logo__image--enabled' : ''
        }`}
        alt="Home Page"
      />

      <img
        key="secondary"
        src={secondaryLogo}
        className={`Logo__image  Logo__secondary-image ${
          !primary ? 'Logo__image--enabled' : ''
        }`}
        alt="Home Page"
      />
    </div>
  );
};

Logo.displayName = 'Logo';

export default Logo;
