import React from "react";
import "./Home.css";

const _iOSDevice = !!navigator.platform.match(/iPhone|iPod|iPad/);

const Home = ({ data }) => (
  <div className={`Home App__section ${!_iOSDevice ? 'Home--fixed' : ''}`}>
    <div className="Home__copy">
      <span
        className="Home__display"
        dangerouslySetInnerHTML={{ __html: data.head.display }}
      />
      <span
        className="Home__lead"
        dangerouslySetInnerHTML={{ __html: data.head.lead }}
      />
    </div>
  </div>
);

Home.displayName = "Home";

export default Home;
