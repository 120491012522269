import React from "react";
import Scroller from "../components/Scroller";
import pages from "../config/pages";
import languageVersion from "../utils/languageVersion";

const Pages = ({language}) =>
  pages
    .filter(({ Component }) => Component)
    .map(({ id, Component, data }) => (
      <Scroller key={id} id={id}>
        <Component data={languageVersion(language, data)} />
      </Scroller>
    ));

Pages.displayName = "Pages";

export default Pages;
