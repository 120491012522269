const isPlainObject = obj =>
  Object.prototype.toString.call(obj) === '[object Object]';

const extractLanguage = language => obj =>
  obj.hasOwnProperty(language) ? obj[language] : obj;

const recursiveIterateKeys = objectAction => obj => {
  if (Array.isArray(obj)) {
    return obj.map(element =>
      recursiveIterateKeys(objectAction)(objectAction(element))
    );
  }

  if (isPlainObject(obj)) {
    return Object.keys(obj)
      .map(key => ({
        [key]: recursiveIterateKeys(objectAction)(objectAction(obj[key])),
      }))
      .reduce((a, b) => ({ ...a, ...b }), {});
  }

  return obj;
};

export default (language, data) => {
  const objectAction = extractLanguage(language);
  const extractRecursive = recursiveIterateKeys(objectAction);

  return extractRecursive(data);
};
