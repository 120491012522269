import React from 'react';
import emitter from 'tiny-emitter/instance';
import { startScrolling } from '../../utils/scrolling';
import getRem from "../../utils/getRem";
import './Scroller.css';

const setLocationHash = id => {
  const { origin, pathname } = window.location;
  const newLocation = `${origin}${pathname}#${id}`;
  window.history.pushState({}, null, newLocation);
};

class Scroller extends React.PureComponent {
  ref = React.createRef();

  handleScrollTo = scrollerId => {
    const { id } = this.props;

    if (id !== scrollerId) {
      return;
    }

    setLocationHash(id);

    const { pageYOffset } = window;
    const { y } = this.ref.current.getBoundingClientRect();

    startScrolling({
      start: pageYOffset,
      distance: Math.round(y),
      id,
    });
  };

  handleScroll = () => {
    const { scrollHover, setScrollHover, unsetScrollHover } = this.props;
    const { y, height } = this.ref.current.getBoundingClientRect();

    const headerMargin = 3 * getRem();
    const hover = y <= 0 && y + height > headerMargin;

    if (!scrollHover && hover) {
      return setScrollHover();
    }

    if (scrollHover && !hover) {
      return unsetScrollHover();
    }
  };

  addListeners = () => {
    window.addEventListener('scroll', this.handleScroll);
    emitter.on('scroll-to', this.handleScrollTo);
  };

  removeListeners = () => {
    window.removeEventListener('scroll', this.handleScroll);
    emitter.off('scroll-to', this.handleScrollTo);
  };

  componentDidMount() {
    this.addListeners();
  }

  componentWillUnmount() {
    this.removeListeners();
  }

  render() {
    const { children, id } = this.props;
    return (
      <span id={id} className="Scroller" ref={this.ref}>
        {children}
      </span>
    );
  }
}

Scroller.displayName = 'Scroller';

export default Scroller;
