import emitter from "tiny-emitter/instance";

const SCROLL_DURATION = 1000;

let params = {
  start: 0,
  distance: 0,
  startTime: 0,
  id: null
};

const resetParams = () => {
  params = {
    start: 0,
    distance: 0,
    startTime: 0,
    id: null
  };
};

const easing = factor => (1 - Math.cos(factor * Math.PI)) / 2;

const handleFrame = () => {
  const { start, distance, startTime, id } = params;

  if (!id) {
    return;
  }
  
  const elapsedTime = Date.now() - startTime;
  const animation = elapsedTime / SCROLL_DURATION;

  if (animation >= 1) {
    return endScrolling();
  }

  const offset = Math.round(distance * easing(animation));

  window.scrollTo(0, start + offset);
  requestAnimationFrame(handleFrame);
};

const endScrolling = () => {
  const { start, distance, id } = params;

  window.scrollTo(0, start + distance + 1);
  setTimeout(() => emitter.emit("scroll-end", id), 100);
  resetParams();
};

export const startScrolling = newParams => {
  if (params.id === newParams.id) {
    return;
  }

  params = { ...newParams, startTime: Date.now() };

  emitter.emit("scroll-start", newParams.id);
  requestAnimationFrame(handleFrame);
};
