import React from "react";
import Image from "../Image";
import getPortfolioImages from '../../utils/getPortfolioImages';
import "./Projects.css";

const Item = ({ title, client, description, id, left }) => (
  <div className={`Projects__item ${left ? "Projects__item--left" : ""}`}>
    <div className={`Projects__info ${left ? "Projects__info--left" : ""}`}>
      <div className="Projects__box">
        <div className="Projects__client">{client}</div>
        <div className="Projects__title">{title}</div>
        {description && (
          <div
            className="Projects__description"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
      </div>
    </div>
    <div className={`Projects__media ${left ? "Projects__media--left" : ""}`}>
      <div className="Projects__images">
        {getPortfolioImages(id).map((image, key) => (
          <Image
            key={key}
            className="Projects__image"
            src={image}
            alt={title}
          />
        ))}
      </div>
    </div>
  </div>
);

Item.displayName = "Item";

export default Item;
