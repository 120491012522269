import React from "react";
import BottomMenu from "./BottomMenu";
import "./Footer.css";

const Footer = ({menu}) => (
  <header className="Footer">
    <BottomMenu items={menu}/>
  </header>
);

Footer.displayName = "Footer";

export default Footer;
