import React from "react";
import Projects from "../../components/Projects";
import Cover from "../../components/Cover";
import "./Portfolio.css";

const Portfolio = ({ data }) => (
  <div className="Portfolio App__section">
    <Cover title={data.cover.title} />
    <Projects projects={data.projects} />
  </div>
);

Portfolio.displayName = "Portfolio";

export default Portfolio;
