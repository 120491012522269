import React from 'react';
import './Prezi.css';
import arrow from '../../assets/png/arrow.png';

const Prezi = ({ data: { id, cover, description } }) => {
  const [opened, setOpened] = React.useState(false);
  const handleClick = React.useCallback(() => {
    setOpened(true);
  }, [setOpened]);

  return (
    <div className="Prezi">
      <div className="Prezi__container">
        {opened ? (
          <iframe
            title="szkolenia"
            id="iframe_container"
            frameBorder="0"
            webkitallowfullscreen="1"
            mozallowfullscreen="1"
            autoPlay="1"
            allowFullScreen="1"
            allow="autoplay; fullscreen"
            className="Prezi__embed"
            src={`https://prezi.com/embed/${id}`}
          />
        ) : (
          <img
            src={cover}
            className="Prezi__cover"
            alt="prezi"
            onClick={handleClick}
          />
        )}

          <div className="Prezi__tooltip">
            <img
              src={arrow}
              className="Prezi__arrow"
              alt="prezi"
            />
            <div className="Prezi__description">{description}</div>
          </div>
        
      </div>
    </div>
  );
};

Prezi.displayName = 'Prezi';

export default Prezi;
