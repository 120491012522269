import React from 'react';
import emitter from 'tiny-emitter/instance';
import Item from './Item';
import './TopMenu.css';
import pages from '../../../config/pages';

class TopMenu extends React.PureComponent {
  state = { scrollingTo: null, mobileEnabled: false };

  handleScrollStart = scrollingTo => {
    if (!pages.map(({ name }) => name).includes(scrollingTo)) {
      return;
    }

    this.setState({ scrollingTo, mobileEnabled: false });
  };

  handleScrollEnd = () => {
    this.setState({ scrollingTo: null });
  };

  mobileToggle = () =>
    this.setState({ mobileEnabled: !this.state.mobileEnabled });

  addListeners = () => {
    emitter.on('scroll-start', this.handleScrollStart);
    emitter.on('scroll-end', this.handleScrollEnd);
  };

  removeListeners = () => {
    emitter.off('scroll-start', this.handleScrollStart);
    emitter.off('scroll-end', this.handleScrollEnd);
  };

  componentDidMount() {
    this.addListeners();
  }

  componentWillUnmount() {
    this.removeListeners();
  }

  render() {
    const { scrollHovers } = this.props;
    const { scrollingTo, mobileEnabled } = this.state;

    const hoveredItem = pages.find(({ id }) => scrollHovers[id]) || {};

    const active = scrollingTo || hoveredItem.id;

    return (
      <>
        <div
          className={`TopMenu__sectionName ${
            mobileEnabled ? 'TopMenu__sectionName--enabled' : ''
          }`}
          onClick={this.mobileToggle}
        >
          {hoveredItem.name}
        </div>
        <div
          className={`TopMenu__toggler ${
            mobileEnabled ? 'TopMenu__toggler--enabled' : ''
          }`}
          onClick={this.mobileToggle}
        >
          &#9776;
        </div>
        <div
          className={`TopMenu ${mobileEnabled ? 'TopMenu--mobileEnabled' : ''}`}
        >
          {pages
            .filter(({ id }) => id !== 'home')
            .map(({ name, id }) => (
              <Item
                key={id}
                name={name}
                selected={active === id}
                onClick={() => {
                  emitter.emit('scroll-to', id);
                  this.setState({ mobileEnabled: false });
                }}
              />
            ))}
        </div>
      </>
    );
  }
}

TopMenu.displayName = 'TopMenu';

export default TopMenu;
