import React from "react";
import "./Steps.css";

const Item = ({ title, description, image, badge }) => (
  <div className="Steps__item">
    <img className="Steps__image" src={image} alt={title} />
    {badge && <div className="Steps__badge">{badge}</div>}
    <div className="Steps__title">{title}</div>
    <div
      className="Steps__description"
      dangerouslySetInnerHTML={{ __html: description }}
    />
  </div>
);

Item.displayName = "Item";

export default Item;
