import React from "react";

const Item = ({ name, selected, onClick }) => (
  <div
    className={`BottomMenu__item ${selected ? "BottomMenu__item--selected" : ""}`}
    onClick={onClick}
  >
    {name}
  </div>
);

Item.displayName = "Item";

export default Item;
