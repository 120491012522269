import powerPoint from '../assets/svg/power-point.svg';
import sztukaPrezentacji from '../assets/svg/sztuka-prezentacji.svg';
import akademiaPrezentacji from '../assets/svg/akademia-prezentacji.svg';
import akademiaPrezentacjiCover from '../assets/prezi/akademia-prezentacji.jpg';

const courses = {
  cover: {
    title: 'Szkolenia',
    description: `
    Jeśli masz coś ważnego do powiedzenia, pozwól się usłyszeć. Nie będziemy Cię zmieniać – znajdziemy Twoje mocne strony i pomożemy Ci je wykorzystać, by Twoja prezentacja (zarówno jej treść jak i forma) była perfekcyjna. Jesteśmy praktykami prezentacji, a nie wykładowcami oderwanymi od praktyki biznesowej. Nasze warsztaty zawierają techniki i narzędzia, które będziesz mógł od razu po szkoleniu wprowadzić do swoich prezentacji.
    `,
  },
  steps: [
    {
      title: 'PowerPoint',
      image: powerPoint,
      description: `
      Zostań mistrzem PowerPointa. Poznaj triki i ukryte funkcje programu, które stosują nasi slidedesignerzy. Celem szkolenia jest szybsza i bardziej produktywna praca podczas przygotowywania slajdów PowerPoint. Szkolenie, które zmieniło życie (z PowerPointem) już setek uczestników.
      `,
    },
    {
      title: 'Sztuka prezentacji',
      image: sztukaPrezentacji,
      description: `
      Szkolenie od którego warto zacząć przygodę z doskonaleniem warsztatu prezentera w PresentPerfect. Obejmuje wszystkie obszary wpływające na sukces prezentacji: storytelling, design, delivery. Prezentuj jak profesjonalista – bądź pewny siebie, wchodź w interakcje z publicznością, używaj skutecznej argumentacji.
      `,
    },
    {
      title: 'Akademia prezentacji',
      image: akademiaPrezentacji,
      description: `
      Nie ma perfekcyjnych prezenterów. Nie ma też słabych prezenterów. Każdy z Twoich pracowników jest unikalny i posiada inne cechy, które przyczynią się do sukcesu Twojej prezentacji. Pomożemy Ci je zdiagnozować, a następnie wypełnić luki w kompetencjach teamu projektując indywidualne ścieżki rozwoju obejmujące wszystkie obszary prezentacji.
      `,
    },
  ],
  prezi: {
    id: 'szt9snkhihts',
    cover: akademiaPrezentacjiCover,
    description: 'Zobacz jak pracujemy',
  },
};

export default courses;
