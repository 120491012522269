const contact = {
  cover: {
    title: 'Kontakt',
  },
  title: 'Present Perfect',
  address: 'Ul. Mokotowska 1<br/>00-640 Warszawa, Poland',
  telephone: {
    prefix: '48',
    number: '513 077 380',
  },
  email: 'kontakt@present-perfect.pl'
};

export default contact;
