import React from "react";
import Cover from "../../components/Cover";
import Steps from "../../components/Steps";
import Prezi from "../../components/Prezi";
import "./Presentations.css";

const Presentations = ({ data }) => (
  <div className="Presentations App__section">
    <Cover {...data.cover} />
    <Steps steps={data.steps} />
    <Prezi data={data.prezi} />
  </div>
);

Presentations.displayName = "Presentations";

export default Presentations;
