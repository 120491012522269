import React from 'react';
import './Logos.css';

const Logos = ({ logos }) => (
  <div className="Logos">
    {logos.map((logo) => (
      <img
        key={logo.image}
        className="Logos__item"
        src={logo.image}
        alt={logo.name}
      />
    ))}
  </div>
);

Logos.displayName = 'Logos';

export default Logos;
