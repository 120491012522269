import React from "react";
import { Provider } from "react-redux";
import Header from "./components/Header";
import Pages from "./pages";
import store from "./store";
import "./App.css";
import "./fonts/webfonts.css";

const App = () => (
  <Provider store={store}>
    <div className="App">
      <Header />
      <Pages />
    </div>
  </Provider>
);

export default App;
