import React from "react";
import Item from "./Item";
import "./Steps.css";

const Steps = ({ steps }) => (
  <div className="Steps">
    {steps.map(step => (
      <Item key={step.title} {...step} />
    ))}
  </div>
);

Steps.displayName = "Steps";

export default Steps;
