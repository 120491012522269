import React from "react";
import Item from "./Item";
import Scroller from "../Scroller";
import Footer from "../Footer";
import "./Projects.css";

const Projects = ({ projects }) => (
  <div className="Projects">
    {projects.map((project, index) => (
      <Scroller key={project.id} id={project.id}>
        <Item {...project} left={index % 2 === 1} />
      </Scroller>
    ))}
    <Footer
      menu={projects.map(({ client, id }) => ({ id, label: client }))}
    />
  </div>
);

Projects.displayName = "Projects";

export default Projects;
