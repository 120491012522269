import React from "react";
import TopMenu from "./TopMenu";
// import Languages from "./Languages";
import Logo from "./Logo"
import "./Header.css";

const Header = () => (
  <header className="Header">
    <Logo />
    <TopMenu />
    {/* <Languages /> */}
  </header>
);

Header.displayName = "Header";

export default Header;
