const importPortfolioImages = () => {
  const context = require.context(
    '../assets/portfolio/',
    true,
    /\.(png|jpg|jpeg|gif|svg|PNG|JPG|JPEG|GIF|SVG)$/
  );
  const files = {};

  context.keys().forEach(filename => {
    const directory = filename.split('/')[1];
    files[directory] = [...(files[directory] || []), context(filename)];
  });

  return files;
};

const porfolioImages = importPortfolioImages();

export default directory => porfolioImages[directory] || [];
