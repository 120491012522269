import React from "react";
import Logos from "../../components/Logos";
import Cover from "../../components/Cover";
import "./Partners.css";

const Partners = ({ data }) => (
  <div className="App__section Partners">
    <Cover title={data.cover.title} />
    <Logos logos={data.logos} />
  </div>
);

Partners.displayName = "Partners";

export default Partners;
