import formatka from '../assets/svg/formatka.svg';
import story from '../assets/svg/story.svg';
import mastering from '../assets/svg/mastering.svg';
import probaGeneralna from '../assets/svg/proba-generalna.svg';
import preziCover from '../assets/prezi/prezi.jpg';

const presentations = {
  cover: {
    title: 'Prezentacje',
    description: `   
    Potrzebujesz profesjonalnej prezentacji, by przekonać innych do swoich pomysłów i zachęcić do działania? Dobrze trafiłeś! W Present Perfect nie tylko projektujemy slajdy PowerPoint i prezentacje Prezi, ale również: analizujemy i interpretujemy dane, układamy narrację, montujemy materiały video i projektujemy pomoce prezentacyjne. Słowem, zrobimy wszystko co w naszej mocy, byś mógł bez stresu wygłosić niezapomnianą prezentację.
    `,
  },
  steps: [
    {
      badge: 1,
      title: 'Formatki',
      image: formatka,
      description: `
      Potrzebujesz szybko tworzyć profesjonalne prezentacje? Zamów firmowy system prezentacyjny (baza wzorcowych slajdów + szkolenie wdrożeniowe) lub gotowy szablon prezentacji PowerPoint.
      `,
    },
    {
      badge: 2,
      title: 'Story',
      image: story,
      description: `
      Czasem dobrze jest, jak ktoś z zewnątrz spojrzy świeżym okiem na prezentację. Przeprowadzimy z Tobą audyt perswazyjności prezentacji i wspólnie ułożymy porywającą argumentację.
      `,
    },
    {
      badge: 3,
      title: 'Mastering',
      image: mastering,
      description: `
      Jeśli przygotowujesz prezentację na ważne spotkanie i nie masz czasu na dopracowanie slajdów - zrobimy to za Ciebie w trybie ekspresowym, by każdy slajd wyglądał profesjonalnie i czytelnie.
      `,
    },
    {
      badge: 4,
      title: 'Próba generalna',
      image: probaGeneralna,
      description: `
      Chcesz poczuć się pewniej w trakcie wystąpienia? Nasi obyci ze sceną i zaprawieni w bojach przetargowych prezenterzy pomogą Ci perfekcyjnie przygotować się do prezentacji.
      `,
    },
  ],
  prezi: {
    id: 'tip8iwrb6rod',
    cover: preziCover,
    description: 'Zobacz nasze prezentacje Prezi',
  },
};

export default presentations;
